import React from 'react';
import './style.css';
import { ROOT_DOMAIN } from '../../modules/api';

const Image = ({ src, alt, className = '' }) => {
  return (
    <img
      className={`image ${className}`}
      data-src={`${ROOT_DOMAIN}${src}`}
      alt={alt}
    />
  );
};
const WCImage = props => (
  <div className={`image-${props.aspectRatio}`}>
    <Image {...props} />
  </div>
);
export default Image;
export { WCImage };
