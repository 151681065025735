import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './styles.css';
import { NETWORK_OK } from '../../modules/error';
const ErrorMessages = () => {
  const errorState = useSelector(state => state.error);
  const dispatch = useDispatch();

  const closeError = () => {
    dispatch({ type: NETWORK_OK });
  };
  return errorState.error ? (
    <div className="wc-error">
      {errorState.error_message}
      <span onClick={closeError}>x</span>
    </div>
  ) : null;
};
export default ErrorMessages;
