import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToCart, reduceFromCart } from '../../modules/cart';
import './style.css';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Image from '../image/index';
import { getCategoryPure } from '../../modules/category';
import { useQuery } from '../../hooks/useAuthentication';
import { useCurrency } from '../../hooks/useCurrency';

class ProductsGrid extends React.Component {
  render() {
    const items = get(
      this.props,
      'items',
      get(this.props, 'category.items', []),
    );
    return (
      <div className="products-grid__container">
        <div className="products-grid">
          {items.length ? (
            items.map(item => (
              <ProductCard data={item} key={item.id} {...this.props} />
            ))
          ) : (
            <p style={{ paddingLeft: '15px' }}>No items found!</p>
          )}
        </div>
      </div>
    );
  }
}

const ProductCard = ({ data, category, ...props }) => {
  const productId = useQuery('productId');
  const [showFullScreen, setFullScreen] = useState(productId === data.id);
  const history = useHistory();
  const [prodCategory, setProductCategory] = useState(category);
  if (!prodCategory) {
    console.log('no category is found');
    setProductCategory({
      is_active: false,
    });
    // call category details.
    getCategoryPure(data.category_id, data.shop_id).then(response => {
      setProductCategory(response);
    });
  }
  const cartItem = props.cart.items.filter(
    cartItem => cartItem.id === data.id,
  )[0];
  const isProductPresentInCart = !isEmpty(cartItem);
  const currency = useCurrency();
  const card = (
    <div className="product-card" data-producid={data.id}>
      <div className="product-card__image-wrapper">
        <Image
          className="product-card__image"
          src={data.image}
          alt={data.name}
        />
      </div>
      <div className="product-card__content-wrapper">
        <h3 className="product-card__title t-h3">{data.name}</h3>
        <p className="product-card__description t-h5">{data.description}</p>
        <p className="product-card__meta">
          <span className="product-card__price t-h4">
            {data.price === data.mrp ? (
              <>
                <span className="product-card__currency">{currency}</span>
                {data.price}
              </>
            ) : (
              <>
                <span className="price--striked">
                  <span className="product-card__currency">{currency}</span>
                  {data.mrp}{' '}
                </span>
                <span className="product-card__currency">{currency}</span>
                {data.price}
              </>
            )}
          </span>
        </p>
        <div className="product-card__meta">
          {isProductPresentInCart ? (
            <>
              <div className="product-card__cart_op">
                <button
                  className="product-card__cart_op product-card__cart_op_empty product-card__cart_op_reduce"
                  onClick={() => props.reduceFromCart(data)}
                >
                  <span className="icon-minus t-h3" />
                  Remove
                </button>
              </div>
              <div className="product-card__cart_op">
                <button className="product-card__cart_op ">
                  <span className="icon-cart t-h3" />
                  <Link to="/cart" type="normal">
                    Checkout
                  </Link>
                </button>
              </div>
            </>
          ) : data.is_active !== false &&
            prodCategory &&
            prodCategory.is_active !== false ? (
            <button
              className="product-card__cart_op product-card__cart_op_empty"
              onClick={() => props.addToCart(data)}
            >
              <span className="icon-plus t-h3" />
              Add to cart
            </button>
          ) : (
            <button
              className="product-card__cta product-card__cta--error"
              disabled
            >
              Sold out
            </button>
          )}
        </div>
      </div>
    </div>
  );
  return showFullScreen ? (
    <div className="product--fullscreen">
      <div>
        {card}
        <button
          className="product-card__cta"
          onClick={() => {
            setFullScreen(false);
            // Navigate to home page.
            history.push('/');
          }}
        >
          Browse other products
        </button>
        <button
          className="product-card__cta"
          onClick={() => {
            setFullScreen(false);
            // Navigate to home page.
            history.push('/cart');
          }}
        >
          Checkout
        </button>
      </div>
    </div>
  ) : (
    card
  );
};

const mapStateToProps = ({ cart, auth }) => ({
  cart: cart,
  auth: auth,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      reduceFromCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductsGrid);
