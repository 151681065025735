import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addToCart } from '../../modules/cart';
import { addToWishlist } from '../../modules/wishlist';
import { getLatestProducts } from '../../modules/trending-products';
import { Tabs } from 'antd';

import ProductsGrid from '../../molecules/products-grid';
import BannerImages from '../../molecules/banner-images';
import get from 'lodash/get';
import { SearchForm } from '../../molecules/search-form';
import styles from './home.module.css';

const { TabPane } = Tabs;

class Home extends React.Component {
  componentDidMount() {
    this.props.getLatestProducts();
  }
  render() {
    return (
      <div>
        <BannerImages />
        <SearchForm />
        <div className={styles.tab_container}>
          <Tabs defaultActiveKey="1">
            {get(this.props.trendingProducts, 'products', []).map(
              (category, index) => {
                return (
                  <TabPane tab={category.name} key={index}>
                    <ProductsGrid category={category} {...this.props} />
                  </TabPane>
                );
              },
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ trendingProducts }) => ({
  trendingProducts: trendingProducts,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      addToWishlist,
      getLatestProducts,
      changePage: () => push('/about-us'),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
