import React from 'react';

import './style.css';
import Image from '../image/index';
import Button from '../button';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useCurrency } from '../../hooks/useCurrency';

const ProductsList = ({ products = [], ...props }) => {
  const totalAmount = products.reduce(
    (total, product) => total + product.price * product.quantity,
    0,
  );
  const isLoggedIn = useSelector(state => get(state, ['auth', 'isLoggedIn']));
  const currency = useCurrency();
  return (
    <div className="products-list">
      {products.map((item, index) => (
        <ProductListItem data={item} key={`${index}-${item.id}`} {...props} />
      ))}
      {!isLoggedIn ? (
        <div className="products-list__row products-list__row--main">
          <p>Subtotal</p>
          <p className="products-list__row__total">
            {currency}
            {totalAmount}
            /-
            <br />
            <span>
              Extra charges may apply. <br />
            </span>
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const ProductListItem = ({ data, ...props }) => {
  const currency = useCurrency();
  return (
    <div className="product-list-item">
      <div className="product-list-item__image-wrapper">
        <Image
          className="product-list-item__image"
          src={data.image}
          alt={data.name}
        />
      </div>
      <div className="product-list-item__title">
        <h5>{data.name}</h5>
        {/* <p className="product-card__description">{data.description}</p> */}
      </div>
      <p className="product-list-item__price">
        {data.price === data.mrp ? (
          `${currency}${data.price}`
        ) : (
          <>
            <span className="price--striked">
              {currency}
              {data.mrp}{' '}
            </span>
            {currency}
            {data.price}
          </>
        )}
      </p>
      {props.removeFrom ? (
        // eslint-disable-next-line react/style-prop-object
        <Button onClick={() => props.removeFrom(data)} style="danger">
          <span className="icon-trash-empty" /> Remove
        </Button>
      ) : null}
    </div>
  );
};

export default ProductsList;
