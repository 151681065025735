import React, { useEffect } from 'react';
import ProductsGrid from '../../molecules/products-grid';
import LoadingIndicator from '../../molecules/loading-indicator';
import MessageBox from '../../molecules/message-box';
import { SearchForm } from '../../molecules/search-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchResults } from '../../modules/searchResults';
import { useQuery } from '../../hooks/useAuthentication';
import get from 'lodash/get';
// import './styles.css';
// A custom hook that builds on useLocation to parse
// the query string for you.
const Search = () => {
  const searchState = useSelector(state => state.searchResults);
  let query = useQuery('q');
  console.log(searchState, query);
  const dispatch = useDispatch();
  const shopId = useSelector(state => get(state, ['shop', 'id']));
  useEffect(() => {
    console.log('query changed');
    dispatch(getSearchResults(query));
  }, [query, shopId, dispatch]);
  const itemsFromThisShop = searchState.searchResults.filter(
    item => item.shop_id === shopId,
  );
  console.log(itemsFromThisShop, searchState.searchResults);
  return (
    <div>
      <SearchForm />
      {searchState.isSearchRequesting ? (
        <LoadingIndicator />
      ) : searchState.searchResults.length ? (
        <ProductsGrid items={itemsFromThisShop} />
      ) : (
        <MessageBox message="No items in this shop for the query. Try searching after changing the shop." />
      )}
    </div>
  );
};

export default Search;
