import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './style.css';

const IconLink = ({
  children,
  icon,
  linkTo = '',
  alt,
  clazzName = '',
  fontIcon,
  showContentAllTime,
  hideChildren,
  ...props
}) => {
  const location = useLocation();
  return (
    <Link
      to={linkTo}
      className={`iconLink ${clazzName} ${
        location.pathname === linkTo ? 'iconLink__active' : ''
      }`}
      {...props}
    >
      {icon ? (
        <img
          className={`icon ${hideChildren ? 'icon--large' : ''}`}
          data-src={icon}
          alt={alt}
        />
      ) : null}
      {fontIcon ? <span className={fontIcon} /> : null}
      <span
        className={`content ${showContentAllTime ? 'content--mobile' : ''} ${
          hideChildren ? 'content--hidden' : ''
        }`}
      >
        {children}
      </span>
    </Link>
  );
};
export default IconLink;
