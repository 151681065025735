import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const WCLink = ({
  to = '/',
  children = 'Goto Homepage',
  type = '',
  ...props
}) => (
  <Link to={to} className={`link link--${type}`} {...props}>
    {children}
  </Link>
);

export default WCLink;
