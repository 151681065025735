export const SHOPS = [
  {
    shop_id: 161,
    chain_id: 12,
    shop_slug: 'localhost',
    colors: {
      primary: '#4682B4',
    },
    name: 'Rendita',
    logo: 'https://i.ibb.co/hmTv1wd/rendita.png',
    config: {
      pageTitle: 'Rendita | Rent Camera and Accessories through Whatsapp',
      pageDescription: 'Rendita | Rent Camera and Accessories through Whatsapp',
      hideBrandNameTitle: true,
      enableDeliveryPicker: true,
      metaDirectory: '/rendita',
      currency: '₹',
    },
  },
  {
    shop_id: 161,
    chain_id: 12,
    shop_slug: 'Rendita',
    colors: {
      primary: '#4682B4',
    },
    name: 'Rendita',
    logo: 'https://i.ibb.co/hmTv1wd/rendita.png',
    config: {
      pageTitle: 'Rendita | Rent Camera and Accessories through Whatsapp',
      pageDescription: 'Rendita | Rent Camera and Accessories through Whatsapp',
      hideBrandNameTitle: true,
      enableDeliveryPicker: true,
      metaDirectory: '/rendita',
      currency: '₹',
    },
  },
  {
    shop_id: 161,
    chain_id: 12,
    shop_slug: 'caffix',
    colors: {
      primary: '#4682B4',
    },
    name: 'Caffix',
    logo: 'https://i.ibb.co/FDLMNmm/caffix-1.png',
    config: {
      pageTitle: 'Caffix Perambra',
      pageDescription: 'Caffix Perambra | The Hub of Tasty',
      hideBrandNameTitle: true,
      metaDirectory: '/caffix',
      currency: '₹',
    },
  },
  {
    shop_id: 162,
    chain_id: 12,
    shop_slug: 'shamnasoven',
    colors: {
      primary: '#000',
    },
    name: "Shamna's Oven",
    logo: 'https://i.ibb.co/ZN0c9Dd/20201107-144029.png',
    config: {
      pageTitle: "Shamna's Oven",
      pageDescription: "Shamna's Oven | Homely and Momely ",
      hideBrandNameTitle: true,
      metaDirectory: '/shamnasoven',
      currency: '₹',
    },
  },
  {
    shop_id: 165,
    chain_id: 12,
    shop_slug: 'shahbakes',
    colors: {
      primary: '#000',
    },
    name: 'Shah Bakes',
    logo: 'https://i.ibb.co/JrLYpY8/shah-bake-logo.png',
    config: {
      pageTitle: 'Shah Bakes',
      pageDescription: 'Shah Bakes | Home Made Bakes',
      hideBrandNameTitle: true,
      metaDirectory: '/shahbakes',
      currency: '₹',
    },
  },
];

// Limtzo: https://coolors.co/000d14-d62828-f77f00-fffffc-faf9ef
