import { SHOPS } from '../config/shops';
import { withRootApi } from './api';
import { throwErr } from './error';
import { getLatestProducts } from './trending-products';
import { get } from 'lodash';

export const LOAD_SHOP = 'store/LOAD';
export const DELETE_SHOP = 'store/CLEAR';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SHOP:
      return {
        ...state,
        ...action.shopDetails,
      };
    case DELETE_SHOP:
      return {};
    default:
      return state;
  }
};

export const LOAD_CHAIN = 'chain/LOAD';
export const DELETE_CHAIN = 'chain/CLEAR';

export const chainReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CHAIN:
      return {
        ...action.chainDetails,
      };
    case DELETE_CHAIN:
      return {};
    default:
      return state;
  }
};

// Action dispatcher
// TODO: Use chain data instead. Considering all shops are under a chain.
// We may need this function if we're able to create a shop without chain.
// Keeping this function for backward compatibilty.
export const loadStoreDetails = () => {
  return (dispatch, getState) => {
    const apiRoot = withRootApi(dispatch, getState, throwErr);
    return apiRoot
      .url('/shop_details.json')
      .get()
      .json(response => {
        response &&
          response.data &&
          dispatch({
            type: LOAD_SHOP,
            shopDetails: response.data,
          });
      });
  };
};

export const updateActiveStore = shop => {
  return (dispatch, getState) => {
    console.log(getState());
    const shopObj = shop.name
      ? shop
      : get(getState(), ['chain', 'shops']).find(s => s.id === shop.id);
    // this is a hack here. delete the current store and update it with the updated store.
    dispatch({
      type: DELETE_SHOP,
    });
    dispatch({
      type: LOAD_SHOP,
      shopDetails: shopObj,
    });
    dispatch(getLatestProducts());
  };
};

export const loadChainDetails = () => {
  const currentShopObject = getShop();
  const chainId = currentShopObject.chain_id;
  console.log(chainId);
  return (dispatch, getState) => {
    console.log(chainId);
    if (chainId) {
      const apiRoot = withRootApi(dispatch, getState, throwErr);
      return apiRoot
        .url('/chain_details.json')
        .query({ chain_id: chainId })
        .get()
        .json(response => {
          if (response && response.data) {
            dispatch({
              type: LOAD_CHAIN,
              chainDetails: response.data,
            });
            const activeShopID = get(getState(), ['shop', 'id']);
            // Update shop also. There's a chance that shop updated their delivery charge and all, it should reflect.
            if (activeShopID) {
              const chain = response.data;
              const shopObj = get(chain, 'shops', []).find(
                shop => shop.id === activeShopID,
              );
              dispatch({
                type: LOAD_SHOP,
                shopDetails: shopObj || get(chain, ['shops', 0], {}),
              });
            }
          }
        });
    }
  };
};
const getShop = () => {
  const shopSlug =
    window.location.host.split('.')[0] === 'www'
      ? window.location.host.split('.')[1]
      : window.location.host.split('.')[0];
  const fallbackShop = {
    shop_id: 161,
    chain_id: 12,
    shop_slug: 'Rendita',
    colors: {
      primary: '#4682B4',
    },
    name: 'Rendita',
    logo: 'https://i.ibb.co/hmTv1wd/rendita.png',
    config: {
      pageTitle: 'Rendita | Rent Camera and Accessories through Whatsapp',
      pageDescription:
        'Rent Camera and Accessories through Whatsapp and Online in Kerala',
      hideBrandNameTitle: true,
      enableDeliveryPicker: true,
      metaDirectory: '/rendita',
      currency: '₹',
    },
  };
  // development purposes
  if (shopSlug.includes('localhost')) {
    return SHOPS.filter(shop => shop.shop_slug === 'localhost')[0];
  }
  return SHOPS.filter(shop => shop.shop_slug === shopSlug)[0] || fallbackShop;
};

export const isOnWhicartDomain = () =>
  window.location.host.indexOf('whicart') > -1;

export { getShop };
