import React from 'react';
import './styles.css';

const Button = ({
  type = 'button',
  value = 'ok',
  onClick,
  children,
  style = '',
  className,
  ...props
}) => (
  <button
    type={type}
    className={`button ${className} button--${style}`}
    onClick={onClick}
    {...props}
  >
    {children || value}
  </button>
);
export default Button;
export { Button };
