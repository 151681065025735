export const ADD_TO_WISHLIST = 'wishlist/ADD_TO_CART_REQUESTED';
// Remove only one item from cart
export const REDUCE_FROM_WISHLIST = 'wishlist/REDUCE_FROM_CART_REQUESTED';
// Delete item
export const REMOVE_FROM_WISHLIST = 'wishlist/REMOVE_FROM_CART_REQUESTED';
// Delete whole Cart
export const CLEAR_WISHLIST = 'wishlist/CLEAR_CART';

const initialState = {
  items: [],
};

export default (state = initialState, action) => {
  // state.items = []
  //
  // Reset cart if somehow cart is cleared
  if (!state.items) {
    state.items = [];
  }
  switch (action.type) {
    case ADD_TO_WISHLIST:
      return {
        ...state,
        items: addToItemsArray(action.item, state.items),
      };

    case REDUCE_FROM_WISHLIST:
      return {
        ...state,
        items: reduceCountFromItemsArray(action.item, state.items),
      };

    case REMOVE_FROM_WISHLIST:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.item.id),
      };

    case CLEAR_WISHLIST:
      return {
        ...state,
        items: [],
      };

    default:
      return state;
  }
};

// Action dispatcher
export const addToWishlist = item => {
  return dispatch => {
    dispatch({
      type: ADD_TO_WISHLIST,
      item: item,
    });
  };
};

// Action dispatcher
export const reduceFromWishlist = item => {
  return dispatch => {
    dispatch({
      type: REDUCE_FROM_WISHLIST,
      item: item,
    });
  };
};

// Action dispatcher
export const removeFromWishlist = item => {
  return dispatch => {
    dispatch({
      type: REMOVE_FROM_WISHLIST,
      item: item,
    });
  };
};

// Action dispatcher
export const clearWholeWishlist = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_WISHLIST,
    });
  };
};

// Helper function for adding item to cart
function addToItemsArray(product = {}, currentItems = []) {
  let itemAlreadyInWishlist = currentItems.filter(
    item => product.id === item.id,
  )[0];
  // OPTIMIZE: this logic
  // Product is already in the cart
  if (itemAlreadyInWishlist) {
    let updatedWishlist = currentItems.reduce((newWishlist, item) => {
      if (item.id === itemAlreadyInWishlist.id) {
        item.quantity++;
      }
      newWishlist.push(item);
      return newWishlist;
    }, []);
    return updatedWishlist;
  }
  // New product
  product.quantity = 1;
  return [product, ...currentItems];
}

function reduceCountFromItemsArray(product = {}, currentItems = []) {
  let updatedWishlist = currentItems.reduce((newWishlist, item) => {
    if (item.id === product.id) {
      item.quantity--;
      if (item.quantity > 0) {
        newWishlist.push(item);
      }
    } else {
      newWishlist.push(item);
    }
    return newWishlist;
  }, []);
  return updatedWishlist;
}
