import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from '../home';
import CategoryPage from '../category';

import Cart from '../cart';

import Header from '../../molecules/header';
import Footer from '../../molecules/footer';
import './styles.css';

import Search from '../../containers/search';
import { Navbar } from '../../molecules/navbar';
import ErrorMessages from '../../molecules/error-messages';
import { getThemeValue } from '../../utils/helpers';
import ComingSoon from '../../atoms/comingSoon';
import { isOnWhicartDomain, updateActiveStore } from '../../modules/shop';
import { useQuery } from '../../hooks/useAuthentication';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

const App = () => {
  const inUat = getThemeValue(['config', 'inUat'], false);
  const shopId = useQuery('shopId');
  const dispatch = useDispatch();
  const chainDetails = useSelector(state => {
    return state.chain;
  });

  // eslint-disable-next-line prettier/prettier
  useEffect(() => {
    console.log(chainDetails, shopId);
    if (shopId) {
      const newShop = get(chainDetails, ['shops'], []).find(
        shop => shop.id === parseInt(shopId),
      );
      console.log(newShop);
      if (newShop) {
        dispatch(updateActiveStore(newShop));
      }
    }
  }, [shopId, chainDetails, dispatch]);
  return inUat && !isOnWhicartDomain() ? (
    <ComingSoon />
  ) : (
    <>
      <Helmet>
        <title>Rendita | Rent Camera and Accessories through Whatsapp </title>
        <meta
          name="description"
          content="Rent Camera and Accessories through Whatsapp and Online in Kerala"
        />
        <link
          rel="manifest"
          href={
            getThemeValue(['config', 'metaDirectory'], '') + '/manifest.json'
          }
        />
        <link
          rel="shortcut icon"
          href={getThemeValue(['config', 'metaDirectory'], '') + '/favicon.ico'}
        />
        <meta
          name="theme-color"
          content={getThemeValue(['colors', 'primary'], '#000000')}
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-57x57.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-60x60.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-72x72.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-76x76.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-114x114.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-120x120.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-144x144.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-152x152.png'
          }
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/apple-icon-180x180.png'
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/android-icon-192x192.png'
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/favicon-32x32.png'
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/favicon-96x96.png'
          }
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/favicon-16x16.png'
          }
        />
        <meta
          name="msapplication-TileColor"
          content={getThemeValue(['colors', 'primary'], '#ffffff')}
        />
        <meta
          name="msapplication-TileImage"
          content={
            getThemeValue(['config', 'metaDirectory'], '') +
            '/ms-icon-144x144.png'
          }
        />
      </Helmet>
      <Header />
      {/* <Carousel /> */}
      <ErrorMessages />
      <main className="main-content">
        <Route exact path="/" component={Home} />
        <Route exact path="/category/:categoryId" component={CategoryPage} />
        <Route exact path="/cart" component={Cart} />
        <Route path="/search" component={Search} />
        {/* <Route exact component={NotFound} /> */}
      </main>
      <Navbar />
      <Footer />
    </>
  );
};

export default App;
