import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import category from './category';
import searchResults from './searchResults';
import trendingProducts from './trending-products';
import cart from './cart';
import shop, { chainReducer } from './shop';
import error from './error';
import order from './order';
import createHistory from 'history/createBrowserHistory';

export const history = createHistory();

export default combineReducers({
  router: connectRouter(history),
  trendingProducts,
  category,
  cart,
  order,
  shop,
  searchResults,
  chain: chainReducer,
  error,
});
