import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import './style.css';
import close from './close.svg';
const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    // this.el = document.createElement('div');
    // this.el.classList.add('modal');
    this.state = {
      showModal: true,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  // componentDidMount() {
  //   modalRoot.appendChild(this.el);
  // }

  // componentWillUnmount() {
  //   modalRoot.removeChild(this.el);
  // }

  closeModal() {
    this.props.onClose();
  }

  render() {
    let children = (
      <div className="modal">
        <div className="modal-content">
          <p className="modal__close">
            <span onClick={this.closeModal}>
              <img src={close} alt="" />
            </span>
          </p>
          {this.props.title ? (
            <h3 className="modal__title">{this.props.title}</h3>
          ) : null}
          {this.props.children}
        </div>
      </div>
    );
    return ReactDOM.createPortal(children, modalRoot);
  }
}

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
