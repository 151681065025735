import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  addToCart,
  removeFromCart,
  reduceFromCart,
  clearWholeCart,
} from '../../modules/cart';
import Link from '../../molecules/link';
import ProductsList from '../../molecules/products-list';
import './style.css';
import LoginModal from '../login-modal';
import { useCurrency } from '../../hooks/useCurrency';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

class Cart extends React.Component {
  constructor() {
    super();
    this.state = {
      showLoginModal: false,
      dates: '',
    };
    this.checkoutAction = this.checkoutAction.bind(this);
    this.closeLoginModal = this.closeLoginModal.bind(this);
    this.onCalendarChange = this.onCalendarChange.bind(this);
  }
  checkoutAction() {
    if (!this.state.dates) {
      alert('No dates selected. Please select dates from the selector');
      return;
    }
    const totalAmount = this.props.cart.items.reduce(
      (total, product) => total + product.price * product.quantity,
      0,
    );
    const currency = useCurrency();
    const items = this.props.cart.items.reduce(
      (acc, item, index) =>
        (acc = acc + `${index + 1}. ${item.name} : ${item.price}%0a`),
      '',
    );
    const shopNumber = this.props.shop.customer_care_number;
    const orderMessage = `----Order Summary----%0a*Dates*: %0a ${this.state.dates}%0a*Items*: %0a${items}%0a%0a*Total*: ${currency} ${totalAmount}/-`;
    window.open(`https://wa.me/91${shopNumber}?text=${orderMessage}`);
  }
  closeLoginModal() {
    this.setState({
      showLoginModal: false,
    });
  }

  onCalendarChange(dates, dateStrings) {
    console.log(dates, dateStrings);
    if (dateStrings[0] && dateStrings[1]) {
      this.setState({
        dates: `${dateStrings[0]} - ${dateStrings[1]}`,
      });
    }
  }
  render() {
    return (
      <div className="cart-page">
        {this.state.showLoginModal ? (
          <LoginModal onClose={this.closeLoginModal} />
        ) : null}
        <div className="cart__title">
          <h1>Cart</h1>
          <p className="cart__items-info">
            {this.props.cart.items.length} items
          </p>
        </div>
        {this.props.cart.items.length ? (
          <div className="cart">
            <ProductsList
              products={this.props.cart.items}
              reduceFrom={this.props.reduceFromCart}
              addTo={this.props.addToCart}
              removeFrom={this.props.removeFromCart}
              {...this.props}
            />
          </div>
        ) : null}
        <div>
          <div className="cart__buttons">
            <div className="cart__buttons__items">
              {this.props.cart.items.length ? (
                [
                  <div>
                    <p>Select dates you want to rent:</p>

                    <RangePicker
                      format="DD:MMM"
                      onCalendarChange={this.onCalendarChange}
                    />
                  </div>,
                  <div
                    onClick={this.checkoutAction}
                    className="link link--success"
                    key="btn"
                  >
                    Checkout
                  </div>,
                  <p key="or">Or</p>,
                ]
              ) : (
                <p>No items in your cart. </p>
              )}
              <Link to="/" type="normal">
                Continue shopping
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cart, auth, address, shop }) => ({
  cart: cart,
  auth: auth,
  address: address,
  shop: shop,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      reduceFromCart,
      removeFromCart,
      clearWholeCart,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
