import wretch from 'wretch';
import { getShop } from './shop';
import get from 'lodash/get';
import { throwErr } from './error';

// shop ID
// TODO: replace query param `shop_id` with header param `shop_slug`.
const currentShopObject = getShop();

const handle403 = () => {
  console.log('handling 403');
};

// export const ROOT_DOMAIN = 'http://dev.fuddio.com/';
export const ROOT_DOMAIN = 'https://api.whicart.in';
// export const API_ROOT = 'http://dev.fuddio.com/api';
export const API_ROOT = 'https://api.whicart.in/api_v1';

// Cross origin authenticated requests on an external API
export const withRootApi = (dispatch, getState, errCallback) => {
  const shopId =
    typeof getState === 'function'
      ? get(getState(), ['shop', 'id'], currentShopObject.shop_id)
      : getState;
  return wretch()
    .catcher(400, err =>
      errCallback(dispatch, 'Bad request. Please try again later!', err),
    )
    .catcher(401, err =>
      errCallback(
        dispatch,
        'Unauthorization issue. Please try re-logging in.',
        err,
      ),
    )
    .catcher(403, err =>
      errCallback(
        dispatch,
        "You don' have permission to do this operation.",
        err,
      ),
    )
    .catcher(404, err =>
      errCallback(
        dispatch,
        "The resource you've been searching for not found.",
        err,
      ),
    )
    .catcher(408, err =>
      errCallback(dispatch, 'Timeout! Please try again!', err),
    )
    .catcher(500, err =>
      errCallback(
        dispatch,
        'Oops! Something went wrong! Please contact support.',
        err,
      ),
    )
    .resolve(_ => _.forbidden(handle403))
    .options({ headers: { Accept: 'application/json' } })
    .url(API_ROOT)
    .query({ shop_id: shopId });
};

export const withHeaderedRootApi = (authentication, shopID, dispatch) => {
  const api = withRootApi(dispatch, shopID, throwErr);
  return api.auth(`Bearer ${authentication['access-token']}`).options({
    headers: {
      'access-token': authentication['access-token'],
      'token-type': 'Bearer',
      client: authentication.client,
      uid: authentication.uid,
    },
  });
};

export const fetchApi = shopId => {
  return wretch()
    .options({ headers: { Accept: 'application/json' } })
    .url(API_ROOT)
    .query({ shop_id: shopId });
};
