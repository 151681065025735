import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import './style.css';
import Image from '../image';
import { loadStoreDetails } from '../../modules/shop';
import LoadingIndicator from '../loading-indicator';
import WCLink from '../link';
import { Carousel } from 'antd';

const BannerImagesHorizontal = props => {
  const dispatch = useDispatch();
  const bannerImages = useSelector(state => {
    return [
      ...get(state, ['shop', 'action_banners'], []),
      ...get(state, ['chain', 'action_banners'], []),
    ];
  });
  const isChainLoaded = useSelector(state => {
    return get(state, ['chain', 'banners']);
  });
  useEffect(() => {
    // TODO: This may not needed
    dispatch(loadStoreDetails());
  }, [dispatch]);
  return (
    <div className="horizontalBanner">
      <div className="horizontalBanner__images">
        <Carousel arrows infinite={true} autoplay autoplaySpeed={3500}>
          {bannerImages && bannerImages.length ? (
            bannerImages.map(({ image, action_category_id }, index) => {
              return action_category_id ? (
                <div>
                  <WCLink
                    to={`/category/${action_category_id}`}
                    type="normal"
                    className="tile"
                    {...props}
                  >
                    <SlideItem index={index} img={image} />
                  </WCLink>
                </div>
              ) : (
                <SlideItem index={index} img={image} />
              );
            })
          ) : !isChainLoaded ? (
            <LoadingIndicator />
          ) : null}
        </Carousel>
      </div>
    </div>
  );
};

const SlideItem = ({ img, onClick }) => {
  return (
    <div className="banner-carousel-slide" onClick={onClick}>
      <div className="banner-carousel__image">
        <Image
          className="banner-carousel-slide__image"
          src={img}
          alt="Banner"
        />
      </div>
    </div>
  );
};

export default BannerImagesHorizontal;
