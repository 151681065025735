import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import './style.css';
import IconLink from '../../atoms/iconLink';

const CartIcon = ({ cart, clazzName }) => {
  return (
    <IconLink
      to="/cart"
      alt="cart"
      fontIcon="icon-basket"
      showContentAllTime
      clazzName={clazzName}
    >
      <span className="cartIconCount">{`${
        get(cart, ['items'], []).length > 0
          ? get(cart, ['items'], []).length
          : '0'
      }`}</span>
    </IconLink>
  );
};

const mapStateToProps = ({ cart }) => ({
  cart: cart,
});

export default connect(mapStateToProps, null)(CartIcon);
