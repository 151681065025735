import React from 'react';
import get from 'lodash/get';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../../modules/cart';
import { addToWishlist } from '../../modules/wishlist';

import ProductsGrid from '../../molecules/products-grid';
import { getCategory } from '../../modules/category';
import WCLink from '../../molecules/link';
import { getLatestProducts } from '../../modules/trending-products';
import TitleBar from '../../atoms/titleBar';

function CategoryPage() {
  const dispatch = useDispatch();
  let { categoryId } = useParams();
  // Here I'm getting only the items in this category. Normally I shouldn't request for other categories.
  dispatch(getCategory(categoryId));
  // But to show the categories list on the header, I need to hit `/categories.json` which will
  // get all products also which is not needed. Unless I get an API to get the details of cateogories without the
  // products I have to call this api. :(
  dispatch(getLatestProducts());

  return (
    <div>
      <CategoryItems />
    </div>
  );
}

const CategoryItems = () => {
  const { category } = useSelector(state => state.category);
  return (
    <div>
      <BreadCrump item={get(category, ['name'], '')} />
      <TitleBar>{get(category, ['name'])}</TitleBar>
      <ProductsGrid
        category={category}
        addToCart={addToCart}
        addToWishlist={addToWishlist}
        key={category.id}
      />
    </div>
  );
};

const BreadCrump = ({ item = '' }) => (
  <div>
    <p>
      <WCLink to="/" type="normal link--inline">
        Home{' '}
      </WCLink>
      / {item}
    </p>
  </div>
);
export default CategoryPage;
