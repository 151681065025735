import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import './style.css';

const Footer = props => (
  <footer className="footer full-width-with-padding">
    <div className="footer__copyright">
      {' '}
      Whitrie Solutions Pvt. Ltd © 2019-2020
    </div>
    <div className="footer__copyright">
      {' '}
      A joint venture with Showcart India
    </div>
    <p className="muted--small text-center">
      Icons made by
      <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
        Freepik
      </a>{' '}
      from{' '}
      <a href="https://www.flaticon.com/" title="Flaticon">
        Flaticon
      </a>
    </p>
    <p className="muted--small text-center">Whicart v0.5.0</p>
  </footer>
);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: () => push('/about-us'),
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(Footer);
