import { withRootApi } from './api';
import { throwErr } from './error';

export const GET_PRODUCTS_REQUESTED = 'products/GET_PRODUCTS_REQUESTED';
export const GET_PRODUCTS = 'products/GET_PRODUCTS';

const initialState = {
  isProductRequesting: true,
  products: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUESTED:
      return {
        ...state,
        isProductRequesting: true,
      };

    case GET_PRODUCTS:
      return {
        ...state,
        products: action.products,
        isProductRequesting: false,
      };

    default:
      return state;
  }
};

export const getLatestProducts = s => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_PRODUCTS_REQUESTED,
    });
    const apiRoot = withRootApi(dispatch, getState, throwErr);
    return apiRoot
      .url('/categories.json')
      .get()
      .json(response => {
        return handleResource(response, dispatch);
      });
  };
};

const handleResource = (response, dispatch) => {
  return dispatch({
    type: GET_PRODUCTS,
    products: response.data,
  });
};
