import React from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import './styles.css';
const HeaderCategoriesList = () => {
  const categories =
    useSelector(state => state.trendingProducts.products) || [];
  return (
    <ul className="header__categories">
      {categories.slice(0, 4).map((category = {}) => (
        <li key={category.id}>
          <Link to={`/category/${category.id}`}>{category.name}</Link>
        </li>
      ))}
    </ul>
  );
};
export default HeaderCategoriesList;
