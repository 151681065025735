import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import CartIcon from '../cart-icon';
import logo from './shopping-cart.svg';

import './style.css';
import LoginModal from '../../containers/login-modal';
import IconLink from '../../atoms/iconLink';
import CategoriesList from '../categorieslList';
import { getThemeValue } from '../../utils/helpers';
import { loadChainDetails } from '../../modules/shop';
const Header = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadChainDetails());
  }, [dispatch]);
  return (
    <div>
      <header className="header full-width-with-padding">
        <div className="header__left">
          <IconLink
            icon={getThemeValue(['logo'], logo)}
            to="/"
            linkTo="/"
            alt="logo"
            clazzName="header__logo"
            showContentAllTime
            hideChildren={getThemeValue(
              ['config', 'hideBrandNameTitle'],
              false,
            )}
          >
            {getThemeValue(['name'], 'Whicart')}
          </IconLink>
          <CategoriesList />
        </div>
        <div className="header__icons">
          <CartIcon />
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth: auth,
});

export default connect(mapStateToProps, null)(Header);

export class LoginLink extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
    this.openLoginModal = this.openLoginModal.bind(this);
  }
  openLoginModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  render() {
    return (
      <div>
        <IconLink fontIcon="flaticon-user" onClick={this.openLoginModal}>
          Login
        </IconLink>
        {this.state.showModal ? (
          <LoginModal onClose={this.openLoginModal} />
        ) : null}
      </div>
    );
  }
}
