import React from 'react';
import { BorderContent } from '../../atoms/border-content';
import IconLink from '../../atoms/iconLink';
import CartIcon from '../cart-icon';
import styles from './Navbar.module.css';

export const Navbar = () => {
  return (
    <BorderContent className={styles.component}>
      <IconLink
        to="/search"
        alt="search"
        fontIcon="icon-search"
        clazzName={styles.iconLink}
      />
      <IconLink
        to="/"
        alt="cart"
        fontIcon="icon-items"
        clazzName={styles.iconLink}
      />
      <CartIcon clazzName={styles.iconLink} />
    </BorderContent>
  );
};
